// eslint-disable-next-line
import React from "react";
// eslint-disable-next-line
import AdSense from "react-adsense";

export default function () {
  return (<></>/*<AdSense.Google
    client='ca-pub-2042974115671345'
    slot='7806394673'
  />*/);
}